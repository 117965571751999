import Layout from '@/components/Layout';
import NavigationMenu from '@/components/modules/navigationMenu';
import SEO from '@/components/seo';
import { Link } from 'gatsby';
import React from 'react';

export default function NotFound() {
  return (
    <Layout>
      <SEO title={`404 Not Found Error`} description={`404 Not Found Error Page`}/>
      <h1 className='text-lg text-center m-4 text-gray-700'>Page Not Found 404 Error!
      </h1>
      <p className='text-md text-center m-2 text-gray-700'>お探しのページは見つかりませんでした。</p>
      <div className='text-center text-gray-700 m-8'>
        <Link to="/">
          <p className='flex justify-center text-gray-700'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
            Topページへ戻る
          </p>
        </Link>
      </div>
    </Layout>
  );
}
